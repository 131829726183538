.carousel {
  position: relative;
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.carousel-track-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  box-sizing: border-box;
  flex: 1 0 auto;
}

.carousel-slide img {
  width: 70%;
  display: block;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.left-button {
  left: 10px;
}

.right-button {
  right: 10px;
}
