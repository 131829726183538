.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  /*height: 100vh;*/
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  }
  
  #post-its {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  #post-it-container {
    position: relative;
    margin: 5px;
    width: 10em;
    height: 10em;
    z-index: 1;
    perspective: 1000px;
  }
  
  #post-it-card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 500ms linear;
  }
  
  #post-it-container:hover #post-it-card {
    transform: rotateY(180deg);
    box-shadow: 2px 2px 9px rgba(194, 194, 194, 0.9);
    cursor: pointer;
  }
  
  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .face.back {
    display: block;
    transform: rotateY(180deg);
    box-sizing: border-box;
    padding: 5px;
    color: black;
    text-align: center;
    background-color: #ffc;
    font-size: 1em;
  }
  
  .strategy {
    margin: 0;
    color: #151515;
    background: #ffc;
    display: block;
    height: 7.5em;
    width: 7.5em;
    padding: 1em;
    text-align: center;
    font-family: "Gloria Hallelujah", "Lato", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.9rem;
    box-shadow: 2px 2px 9px rgba(194, 194, 194, 0.5);
  }
  
  .quote-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .button-wallet-ens{
    width: 40px;
    height: 40px;
  }
  
  .button-moon, .button-rekt {
    cursor: pointer;
  }
  
  .vote-option {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .vote-option span {
    margin-left: 5px;
    font-size: 1em;
    color: black;
    font-weight: bold;
  }
  
  #post-its div#post-it-container:nth-child(1) {
    transform: rotate(-5deg);
    position: relative;
    top: -1px;
  }
  
  #post-its div#post-it-container:nth-child(even) {
    transform: rotate(4deg);
    position: relative;
    top: 5px;
  }
  
  #post-its div#post-it-container:nth-child(4n) {
    transform: rotate(-3deg);
    position: relative;
    top: -5px;
  }
  
  #post-its div#post-it-container:nth-child(7n) {
    transform: rotate(5deg);
    position: relative;
    top: -10px;
  }
  
  p.quote-text {
     user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    height: 350px;
    width: 330px!important;
  }

  .quote-date {
    
  }

  .button-enter {
    width: 400px;
  }

  .button-enter:hover {
    transform: scale(1.1);
    transition: all 0.3s ease !important;
  }

  @keyframes heartbeat {
    0% { transform: scale(1); }
    50% { transform: scale(1.025); }
    100% { transform: scale(1); }
  }

  .intro-image {
    position: relative !important;
    animation: heartbeat 1s infinite !important;
  }

  #hover08 {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;

  }
  
  #hover08:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  
  .welcome-title {
    color: black !important;
    font-size: 5.5em !important;
    text-transform: uppercase !important;
    padding: 0 !important;
    margin-bottom: -0.1em !important;
    transition: color .3s ease-in-out !important;
  }

  .welcome-title:hover {
    color: red !important;
  }

  @media (max-width: 600px) {
    .welcome-title {
      font-size: 3em !important;
    }
  }

  @keyframes swirlOut {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: rotate(720deg) scale(0);
      opacity: 0;
    }
  }

  @keyframes bounceOut {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(50px, -50px);
    }
    40% {
      transform: translate(-50px, 50px);
    }
    60% {
      transform: translate(50px, 50px);
    }
    80% {
      transform: translate(-50px, -50px);
    }
    100% {
      transform: translate(0, 0) scale(0);
      opacity: 0;
    }
  }
  
  .welcome-container {
    transition: all 0.5s ease-in-out;
  }
  
  .swirl-out {
    animation: swirlOut 1s forwards;
  }
  
  .bounce-out {
    animation: bounceOut 1s forwards;
  }

  .quote-link {
    text-decoration: none;
    color: inherit;
  }

  
  .quote-card2 {
    background-color: white;
    color: black;
    border: 0px;
    width: 380px;
    height: 590px;
    background-repeat: no-repeat;
    padding: 0px;
    transition: transform 0.3s;
    background: url('./border-quotes-wojaks.png') no-repeat;
    background-position: center top;
    z-index: 9 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .vote-thanks {
    position: absolute;
    width: 325px;
    height: 45px;
    z-index: 999;
    margin-left: -15px;
    margin-top: -5px;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
  .vote-thanks.moon {
    background: #57c355; /* Green background for moon vote */
    border: 2px solid black;
  }
  
  .vote-thanks.rekt {
    background: #ff5555; /* Red background for rekt vote */
    border: 2px solid black;
  }
  
  .vote-thanks p {
    margin: 0;
    font-size: 17px;
    font-weight: bold;
  }
  
  .vote-thanks a {
    margin-left: 5px;
    font-size: 14px;
    color: white; /* Adjust the color as needed */
    text-decoration: underline;
  }
  
  .check-lucky {
    background: white;
    padding: 5px;
    border: 2px solid #000; /* Borde del botón */
    transition: all 0.3s ease; /* Efecto de transición suave */
  }