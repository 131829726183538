@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';

@font-face {
  font-family: "Veri";
  src: url('/fonts/Veri.ttf') format('truetype');
}

@font-face {
  font-family: "Luki";
  src: url('/fonts/Luki.ttf') format('truetype');
}

body {
  font-family: 'Luki', Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: red !important;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  cursor: url('../src/components/mouse1.cur'), auto !important;
}

label:hover, .vote-option span {
  cursor: url('../src/components/mouse1.cur'), auto !important;
}

a:hover, button:hover, select:hover, .button-moon:hover, .button-rekt:hover, .delete-option:hover, .vote-thanks.moon span, .vote-thanks.rekt span, .button-wojak-info, .referral-link-button {
  cursor: url('../src/components/mouse2.cur'), auto !important;
}

p {
  font-size: 1.6em;
  line-height: 1 !important;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: white;
  color: black;
}

/* Scrollbar styles for Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background-color: #dc143c;
  border-radius: 10px;
  border: 2px solid black;
}

/* Scrollbar styles for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dc143c white;
}

/* Scrollbar styles for Edge, IE */
::-ms-scrollbar {
  width: 12px;
}

::-ms-scrollbar-track {
  background: black;
}

::-ms-scrollbar-thumb {
  background-color: #dc143c;
  border-radius: 10px;
  border: 2px solid black;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.nav-container {
  /*display: flex;
  justify-content: flex-end;
  align-items: center;*/
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.nav-links a:hover {
  color: #dc143c;
  transition: all 0.3s ease !important;
}

.nav-links button {
  background: none;
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.nav-links button:hover {
  text-decoration: underline;
  transition: all 0.3s ease !important;
}

.tw-connect-wallet {
  background: url('../src/components/connect_wallet.png') !important;
  background-position: center !important;
  background-size: cover !important;
  height: 67px !important;
  min-width: 210px !important;
  font-size: 0 !important;
  /*padding: 10px 15px !important;
  border-radius: 5px !important;
  color: black !important;*/
  border: 0px !important;
}

.register-now {
  background: url('../src/components/register-now.png') !important;
  background-position: center !important;
  background-size: cover !important;
  height: 55px !important;
  min-width: 135px !important;
}

.tw-connect-wallet:hover {
  /*background-color: #dc143c !important;
  color: white !important;
  text-decoration: none !important;
  transition: all 0.3s ease !important;*/
}

.quote-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la ventana */
  background-color: #f0f0f0; /* Color de fondo */
}

.quote-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #ccc; /* Borde */
  border-radius: 10px; /* Bordes redondeados */
  background-color: #fff; /* Fondo blanco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
}

.quote-input {
  width: 300px; /* Ancho del textarea */
  height: 100px; /* Altura del textarea */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Bordes redondeados */
  font-size: 16px; /* Tamaño de fuente */
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px; /* Bordes redondeados */
  background-color: #dc143c; /* Color de fondo */
  color: #fff; /* Color del texto */
  font-size: 16px; /* Tamaño de fuente */
  cursor: pointer; /* Cursor pointer */
}

.submit-button:hover {
  background-color: #a40000; /* Color de fondo al pasar el cursor */
  transition: all 0.3s ease !important;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.main-container h1 {
  text-align: center;
  padding-top: 0.1em;
  max-width: 100%;
}

.quote-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  padding: 20px;
  justify-content: center; /* Centrar el grid */
}

.quote-list-container-main {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  row-gap: 0;
  padding: 20px;
  padding-bottom: 0px;
  justify-content: center; /* Centrar el grid */
}

@media (max-width: 800) {
  .quote-list-container {
    grid-template-columns: 1fr;
  }

  .quote-list-container-main {
    grid-template-columns: 1fr;
  }
}

p.quote-text {
  background: #ffffffd4;
  color: black;
  width: 100%;
}

.quote-card {
  background-color: white;
  color: black;
  border: 0px;
  height: 600px;
  width: 380px;
  background-repeat: no-repeat;
  padding:0px;
  transition: transform 0.3s;
  background: url('../src/components/border-quotes-wojaks.png') no-repeat;
  background-position: center top;
  z-index: 9 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ad-quote-card {
  background-image: url('../src/components/border-quotes-ads.png');
}

/*.green-background {
  background: url('../src/components/border-quotes-green.png');
}

.red-background {
  background: url('../src/components/border-quotes-red.png');
}*/

.quote-card:hover {
  transform: scale(1.015);
  /*background-color: #a40000;*/
  transition: all 0.3s ease !important;
}

.quote-text {
  font-size: 27px;
  font-weight: bold;
  line-height: 1.1em !important;
  margin: 5px 0; /* Reduce el margen para menos espacio vertical */
  color: black;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 5px; /* Agrega un poco de padding superior e inferior */
  padding-bottom: 5px; /* Agrega un poco de padding superior e inferior */
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}


.quote-author {
  font-size: 14px;
  text-align: right;
  color: #7e7d86;
  margin-bottom: 0;
  font-size: 0.7em;
}

.quote-footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  margin-left: 1em;
}

.quote-footer button {
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  padding: 2px 10px;
  cursor: pointer;
}

.approve-button {
  background-color: green !important;
  color: white !important;
}

.reject-button {
  background-color: red !important;
  color: white !important;
}

.submit-quote-button {
  /*background-color: #dc143c !important;*/
  text-decoration: none;
  color: #dc143c;
  font-weight: bold;
  /*padding: 10px 15px;
  border-radius: 5px;*/
  transition: background-color 0.3s;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.play-pause-button {
  position: relative;
  top: 3px;
}

.submit-quote-button:hover {
 /* color: black !important;
  background-color: #dc143c;
  text-decoration: none !important;
  transition: all 0.3s ease !important;*/
}

.upload-image {
  height:44px;
}

.upload-image:hover {
  transform: scale(1.1);
  transition: all 0.3s ease !important;
}

.button-image {
  height:auto;
}

.linkx-image:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}


.airdrop-image {
  height:auto;
  position: relative;
  top: 3px;
}

.airdrop-image:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.ranking-image {
  height:auto;
  position: relative;
  top: 3px;
  /*cursor: not-allowed;*/
}

.ranking-image:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.button-image:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.connect-wallet-btn:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.twitter-button, .telegram-button {
  height:40px;
}

.twitter-button2, .telegram-button2, .dexs-button2, .dext-button2, .uni-button2, .gitbook-button2 {
  height:50px;
}

.twitter-button2:hover, .telegram-button2:hover, .dexs-button2:hover, .dext-button2:hover, .uni-button2:hover, .gitbook-button2:hover {
  transform: scale(1.10);
  transition: all 0.3s ease !important;
  
}

.wojak-share-button {
  height:35px;
  margin-right: 0.5em;
}

.twitter-button:hover, .telegram-button:hover {
  transform: scale(1.10);
  transition: all 0.3s ease !important;
}

.leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.leaderboard-container h1 {
  font-size: 48px;
  color: #555;
}

.my-quotes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  padding: 20px;
  background-color: black;
  justify-content: center; /* Centrar el grid */
}

@media (max-width: 800) {
  .my-quotes-container {
    grid-template-columns: 1fr;
  }
}

.my-quotes-container h1 {
  margin-bottom: 20px;
}

.my-quotes-container ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  justify-content: center;
  gap: 20px;
}

.my-quotes-container li {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.my-quotes-container p {
  font-size: 25px;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.blur-background {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out; /* Para una transición suave */
}

.logo-image {
  height: auto;
  width: auto;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.logo-image:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.admin-container {
  padding: 20px;
  text-align: center;
  color: black;
  width: 100%;
}

.admin {
  /*padding: 10px 20px;*/
  border: none;
  border-radius: 5px; /* Bordes redondeados */
  /*background-color: red;*/
  color: white !important; /* Color del texto */
  font-size: 16px; /* Tamaño de fuente */
  cursor: pointer; /* Cursor pointer */
  font-weight: bold;
}

.admin:hover {
  transition: all 0.3s ease !important;
}

.my-quotes {
  /*border: 2px white solid;
  border-radius: 5px;
  padding: 9px 10px;*/
}

.text-carousel-wrapper {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100vw;
  background: #dc143c;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

.text-carousel {
  font-family: 'Veri', Arial, sans-serif;
  display: inline-block;
  white-space: nowrap;
  animation: text-scroll 10s linear infinite;
  animation: text-scroll 10s linear infinite;
  letter-spacing: -1px;
}

/* styles.css */
.text-carousel-wrapper {
  font-family: 'Veri', Arial, sans-serif !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.centered-title {
  text-align: center; /* Asegura que el texto esté centrado */
}

.text-align-center {
  text-align: center;
}


@keyframes text-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.button-moon, .button-rekt {
  cursor: pointer;
}

.button-moon:hover, .button-rekt:hover, .referral-link-button:hover, .connect-wallet-button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.copy-referral:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.button-wallet-ens {
  margin-right: 0.75em;
}

.button-wojak-info {
  height: 35px;
  margin-left: 1.25em;
  animation: zoomInOut 2.5s infinite;
}

.quote-image {
  /*max-width: 90%;
  height: auto;*/
}

.quote-image-background {
  /*display: flex;
  justify-content: center;
  width: 100%;*/
}

.tw-connected-wallet .tw-connected-wallet__address {
  color: black !important;
  font-size: 1em !important;
  font-weight: bold !important;
}

button.tw-connected-wallet.connect-wallet-btn {
  min-width: 120px;
}

.tw-connected-wallet__balance {
  font-size: 0.9em !important;
}

.tw-connected-wallet:hover {
  background-color: transparent !important;
}

body {
  font-family: 'Luki', Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  background: white!important;
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
  overflow-x: hidden;
}



select#sortOrder {
  color: black;
  width: 120px;
}

textarea#formQuote {
  background: black;
  color: white !important;
  border: 3px solid #0051fe;
  border-radius: 0px;
  height: 325px;
  text-align: center;
  padding: 25px;
  font-size: 1.6rem;
}

.dextools-chart {
  color: black !important;
  border: 3px solid black !important;
  height: 50px !important;
  padding: 5px !important;
  font-size: 1.4rem !important;
  scrollbar-width: none !important;
}

.single-line {
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow-x: auto;    /* Habilita el desplazamiento horizontal si el texto es muy largo */
  resize: none;        /* Deshabilita el redimensionamiento */
}

.modal-title.h4 {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: -1em;
}

button.mt-3.btn.btn-primary {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
}

button.tw-connected-wallet {
  background: antiquewhite;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  height: 50px;
}

.quotes-main {
  color: black !important;
  font-size: 1.8em !important;
  text-transform: uppercase !important;
}

.enter-button {
  color: black !important;
  font-size: 3em !important;
  text-transform: uppercase !important;
  padding-left: 15px;
  padding-right: 15px;
  border: 3px solid black;
}

.enter-button:hover {
  color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  border: 3px solid black;
  background-color: #ff0000;
  transition: all 0.3s ease !important;
}

.welcome-container {
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.referral-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copy-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

@media (max-width: 768px) {
.logo-image {
      height: 80px;
      margin-right: 50px;
}

.main-container h1 {
  /*font-size: 1.6em;*/
}

h1.welcome-title {
  line-height: 3.5rem;
  font-size: 4rem !important;
  margin-bottom: 0.15em !important;
}

.play-pause-button {
  display: none;
}

.admin-button {
  width: 150px;
}
}

@media (max-width: 600px) {
  img#hover08 {
    width: 95%;
    max-width: 100%;
    margin: 0 auto;
}

.quote-card:hover {
  transform: scale(1) !important;
  transition: all 0.3s ease !important;
}

.contract-ca {
  font-size: 1.2em !important;
}

.pagination {
  margin-bottom: 1em;
  margin-top: 0em !important;
}

.main-container {
  padding: 0px;
}

.airdrop-button-mobile, .admin-button-mobile {
  width: 200px;
  height: auto;
}

.add-new-button-mobile {

}

.close-button-sidebar {
  float: right;
  position: relative;
  top: -0.5em;
  font-size: 2.5em;
}

.connected-wallet-button-sidebar {
  min-width: 210px !important;
  width: 200px !important;
}

.play-pause-button {
  display: initial;
  /*top: 10px;
  left: 10px*/
}

svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.welcome-container {
  height: 95vh;
  margin-top: 0;
}

.max-w-md {
  max-width: 23rem !important;
}

.main-content {
  align-items: initial !important;
}

.quote-list-container-main {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.carousel-subtitle {
  display: none;
}

.modal-title.h4 {
  font-size: 2rem;
}

.modal-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-close {
  right: -0.5em !important;
}

.referrals-table {
  /*min-width: 100% !important;*/
}

.wallet-mobile {
  font-size: 0.8em !important;
}

.invited-by {
  font-size: 1.8em !important;
}

.text-carousel-wrapper {
  border-top: 2px solid black;
}

.text-degen-calculator-mobile {
  font-size: 0.9em;
  display: block;
}

.socials-container {
  gap: 25px !important; /* Gap for mobile devices */
}

.twitter-button2, .telegram-button2, .dexs-button2, .dext-button2, .uni-button2, .gitbook-button2 {
  height:35px;
}

}


.w-64.h-full.p-4.bg-white {
  z-index:99999!important;
}


.share-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.6em;
  gap: 10px;
  font-size: 1.45em;
}

.share-buttons button {
  background: none;
  border: none;
  cursor: pointer;

}

.quote-footer-share {
  display: flex;
  justify-content: center;
  align-items: center;
}


.centered-title {
  padding-top: 0.1em;
  margin-bottom: 10px;
}

.referral-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  margin-bottom: 3em;
}

.connect-wallet-button {
  margin-top: 0px !important;
  padding: 8px 15px !important;
  font-size: 25px !important;
  background-color: #588c39 !important;
  color: white;
  border: none;
  border-radius: 5px;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 5px;
  color: black;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.referrals-table-container {
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  margin-bottom: 2em;
  max-width: 100%;
}

.referrals-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.referral-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copy-button {
  margin-top: 20px;
  cursor: pointer;
}

.referrals-table {
  /*width: 100%;*/
  min-width: 500px;
  margin-top: 10px;
  border-collapse: collapse;
}

.referrals-table th, .referrals-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.referrals-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
  font-size: 1.5em;
}


.referral-container {
  min-height: auto;
  margin-top: 2em;
}

.container-airdrop-referrals {
  height: 65vh;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  width: 100%;
}

.connect-wallet-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.manual-referral-code-container {
 
}

.manual-referral-code-input {
  padding: 10px;
  font-size: 20px;
  margin-right:-3px!important;
  border: 1px solid green;
  border-radius: 0px;

}

.manual-referral-code-button {
  padding: 10px 10px;
  font-size: 20px;
  background-color: #588c39;
  color: white;
  border: none;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 1.4em;
}

.bg-grana{ background-color: #dc143c;}

.bg-gris {background-color: #000000 !important;}


/* src/components/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

p.text-lg.font-semibold.text-white {
  margin-top: 15px;
}

.twitter-box {
  background: #1DA1F2 !important;
}

placeholder{color:white!important;}

/* Placeholder blanco */
::-webkit-input-placeholder {
  color: #ffffff !important; /* Para navegadores basados en WebKit como Chrome y Safari */
}

:-moz-placeholder {
  color: #ffffff !important; /* Para Firefox 18- */
}

::-moz-placeholder {
  color: #ffffff !important; /* Para Firefox 19+ */
}

:-ms-input-placeholder {
  color: #ffffff !important; /* Para IE */
}


.modal-content {
  background: white;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.btn-close {
  position: relative !important;
  top: -2.5em !important;
  right: -1em;
}

.text-characters {
  font-size: 1.2em !important;
}

.modal-header {
  flex-direction: column !important;
}

.modal-body {
  font-size: 1.2em !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-body form {
  width: 100% !important;
}

.x-login {
  background-color: black !important;
  display: flex !important;
  justify-content: center !important;
  width: fit-content !important;
}

.contract-text {
  font-family: 'Luki', Arial, sans-serif !important;
  letter-spacing: 1px;
}

.copy-ca-icon {
  position: relative;
  top: -5px;
}

.carousel-title {
  letter-spacing: -2px !important;
}

.votes-moon-counter {
  position: relative;
  left: -2.5em;
}

.votes-rekt-counter {
  position: relative;
  left: -2.5em;
}

.vote-option.voted {
  opacity: 0.3;
}

.flex.items-center.p-2.rounded-md.twitter-box {
  background-color: white !important;
  border: 0px !important;
  padding: 0px !important;
} 

.contract-ca {
  font-family: 'Luki', Arial, sans-serif !important;
}

.copy-ca-button {
  position: relative;
  top: -7px;
}

select {
  color: black;
  background-color: white;
}

select option {
  color: black !important;
  background-color: white !important;
}

svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.ensens {
  font-size: 1.2em !important;
}

.congratulations, .thank-you {
  font-weight: bold;
  font-size: 1.2em;
}

tbody {
  font-size: 1.4em !important;
}

.round-image {
  border-radius: 50%;
  border: 1px solid black;
}

.twitter-link {
  text-decoration: none !important;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.rounded-full {
  border: 2px solid black;
}

.quote-card.selected {
  background-image: url('/src/components/border-quotes-selected.png'); /* Ajusta la ruta según la ubicación de la imagen */
  background-size: cover;
  background-repeat: no-repeat;
  border: none; /* Opcional: Remover el borde si la imagen ya incluye un borde */
}

.bulk-action-buttons {
  display: flex;
  gap: 30px;
  justify-content: center;
  width: 95%;
}

.approve-button-pending {
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  gap: 5px;
  align-items: center;
  background-color: green;
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  padding: 0px 10px;
}

.reject-button-pending {
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  gap: 5px;
  align-items: center;
  background-color: red;
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px 10px;
}

.approve-now-button {
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  gap: 5px;
  align-items: center;
  background-color: #ddbb24;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px 10px;
}

.mfc-holder {
  width: 110px;
}

.wojak-points {
  width: 80px;
}

.wojak-bonus-points {
  width: 80px;
}

.wojak-total-points {
  width: 65px;
}

.profit-calculator {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
}

.profit-calculator input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 350px;
  font-size: 0.9em;
  text-align: center;
}

.profit-calculator button {
  padding: 5px 20px;
  margin: 10px;
  cursor: pointer;
  background: black;
  color: white;
  border: 2px solid black;
  border-radius: 10px;
}

.profit-calculator button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease !important;
}

.profit-calculator div {
  margin-top: 10px;
  margin-bottom: 0.5em;
}

.input-placeholder::placeholder {
  color: black !important;
}

#footer {
  text-align: center;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  border-bottom: 0px;
}

.socials-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 0.5rem;
  align-items: center;
}

.partners-mobile {
  display: none;
}

@media (max-width: 1750px) {
.partners {
  display: none !important;
}

.partners-mobile {
display: block !important;
width: 100%;
padding-bottom: 1em;
}
}

@media (min-width: 769px) and (max-width: 1250px) {
  .quote-list-container-main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .quote-list-container-main {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1280px) {
.connected-wallet-button-sidebar {
  min-width: 210px !important;
  width: 200px !important;
}
.add-new-button-mobile {
  position: relative;
  left:-8px;;
}
}

.blur {
  filter: blur(5px);
  transition: filter 0.3s;
}

.image-right{
  position: fixed;
  bottom: 0;
  width: 300px;
  left: 380px;
}

.image-left {
  position: fixed;
  bottom: 0;
  width: 300px;
  right: 380px;
}

.quote-card-placeholder {
  background-color: white;
  color: black;
  border: 0px;
  height: 490px;
  background-repeat: no-repeat;
  padding: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: auto;
  background: url('../src/components/border-quotes.png') no-repeat;
  background-position: center top;
}

.quote-card-placeholder .placeholder-header,
.quote-card-placeholder .placeholder-footer {
  height: 20px;
  margin-bottom: 10px;
}

.quote-card-placeholder .placeholder-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.quote-text-placeholder {
  font-size: 25px;
  background: black;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px 27px;
  border: 3px solid black;
}

/* Ejemplo de transición CSS */
.main-content {
  transition: opacity 0.3s ease-in-out;
}

.quote-footer-share {
  margin-top: -1em;
  margin-bottom: 2em;
  padding: 10px;
  padding-top: 0;
  border: 3px solid black;
}

.advertisement-image:hover {
  filter: blur(0px) !important;
  transition: filter 0.3s ease !important;
}

.custom-file-input {
  display: none; /* Oculta el input real */
}

.custom-file-label {
  background-color: white; /* Cambia el color de fondo del botón */
  border: 2px solid black;
  color: black; /* Cambia el color del texto */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.3em;
  text-align: center;
  display: inline-block;
}

.custom-file-label:hover {
  background-color: #dc143c; /* Cambia el color al pasar el ratón */
  color: white;
  cursor: pointer !important;
}

.button-gorra:hover {
  background-color: #9e0a27 !important; /* Cambia el color al pasar el ratón */
}

.dextools {
  width: 125px;
  padding-left: 0.5em;
  padding-top: 0.30em;
  padding-bottom: 0.30em;
}

/* COINS QUOTECARDS BACKGROUNDS */
.quote-card.btc-background, .quote-card2.btc-background {
  background: url('../src/components/border-quotes/coins/btc.png') no-repeat;
}

.quote-card.eth-background, .quote-card2.eth-background {
  background: url('../src/components/border-quotes/coins/eth.png') no-repeat;
}
.quote-card.eth-background .ensens, .quote-card2.eth-background .ensens {
  color: #ffffff;
}

.quote-card.sol-background, .quote-card2.sol-background {
  background: url('../src/components/border-quotes/coins/sol.png') no-repeat;
}

.quote-card.polkadot-background, .quote-card2.polkadot-background {
  background: url('../src/components/border-quotes/coins/polkadot.png') no-repeat;
}
.quote-card.polkadot-background .ensens, .quote-card2.polkadot-background .ensens {
  color: #ffffff;
}

.quote-card.terra-luna-background, .quote-card2.terra-luna-background {
  background: url('../src/components/border-quotes/coins/terra-luna.png') no-repeat;
}
.quote-card.terra-luna-background .ensens, .quote-card2.terra-luna-background .ensens {
  color: #ffffff;
}

.quote-card.bnb-background, .quote-card2.bnb-background {
  background: url('../src/components/border-quotes/coins/bnb.png') no-repeat;
}

.quote-card.xrp-background, .quote-card2.xrp-background {
  background: url('../src/components/border-quotes/coins/xrp.png') no-repeat;
}
.quote-card.xrp-background .ensens, .quote-card2.xrp-background .ensens {
  color: #ffffff;
}

.quote-card.ada-background, .quote-card2.ada-background {
  background: url('../src/components/border-quotes/coins/ada.png') no-repeat;
}

.quote-card.kas-background, .quote-card2.kas-background {
  background: url('../src/components/border-quotes/coins/kas.png') no-repeat;
}

.quote-card.matic-background, .quote-card2.matic-background {
  background: url('../src/components/border-quotes/coins/matic.png') no-repeat;
}
.quote-card.matic-background .ensens, .quote-card2.matic-background .ensens {
  color: #ffffff;
}

.quote-card.uni-background, .quote-card2.uni-background {
  background: url('../src/components/border-quotes/coins/uni.png') no-repeat;
}

.quote-card.inj-background, .quote-card2.inj-background {
  background: url('../src/components/border-quotes/coins/inj.png') no-repeat;
}

.quote-card.xmr-background, .quote-card2.xmr-background {
  background: url('../src/components/border-quotes/coins/xmr.png') no-repeat;
}

.quote-card.arb-background, .quote-card2.arb-background {
  background: url('../src/components/border-quotes/coins/arb.png') no-repeat;
}
.quote-card.arb-background .ensens, .quote-card2.arb-background .ensens {
  color: #ffffff;
}

.quote-card.paal-background, .quote-card2.paal-background {
  background: url('../src/components/border-quotes/coins/paal.png') no-repeat;
}
.quote-card.paal-background .ensens, .quote-card2.paal-background .ensens {
  color: #ffffff;
}

.quote-card.metamask-background, .quote-card2.metamask-background {
  background: url('../src/components/border-quotes/coins/metamask.png') no-repeat;
}

.quote-card.trx-background, .quote-card2.trx-background {
  background: url('../src/components/border-quotes/coins/trx.png') no-repeat;
}
.quote-card.trx-background .ensens, .quote-card2.trx-background .ensens {
  color: #ffffff;
}

.quote-card.ftx-background, .quote-card2.ftx-background {
  background: url('../src/components/border-quotes/coins/ftx.png') no-repeat;
}

/* MEMECOINS QUOTECARDS BACKGROUNDS */
.quote-card.duko-background, .quote-card2.duko-background {
  background: url('../src/components/border-quotes/memes/duko.png') no-repeat;
}

.quote-card.mog-background, .quote-card2.mog-background {
  background: url('../src/components/border-quotes/memes/mog.png') no-repeat;
}

.quote-card.pepe-background, .quote-card2.pepe-background {
  background: url('../src/components/border-quotes/memes/pepe.png') no-repeat;
}

.quote-card.wif-background, .quote-card2.wif-background {
  background: url('../src/components/border-quotes/memes/wif.png') no-repeat;
}

.quote-card.doge-background, .quote-card2.doge-background {
  background: url('../src/components/border-quotes/memes/doge.png') no-repeat;
}

.quote-card.shiba-background, .quote-card2.shiba-background {
  background: url('../src/components/border-quotes/memes/shiba.png') no-repeat;
}

.quote-card.bonk-background, .quote-card2.bonk-background {
  background: url('../src/components/border-quotes/memes/bonk.png') no-repeat;
}

.quote-card.brett-background, .quote-card2.brett-background {
  background: url('../src/components/border-quotes/memes/brett.png') no-repeat;
}

.quote-card.popcat-background, .quote-card2.popcat-background {
  background: url('../src/components/border-quotes/memes/popcat.png') no-repeat;
}

.quote-card.ponke-background, .quote-card2.ponke-background {
  background: url('../src/components/border-quotes/memes/ponke.png') no-repeat;
}

.quote-card.neiro-background, .quote-card2.neiro-background {
  background: url('../src/components/border-quotes/memes/neiro.png') no-repeat;
}

.quote-card.npc-background, .quote-card2.npc-background {
  background: url('../src/components/border-quotes/memes/npc.png') no-repeat;
}

.quote-card.barsik-background, .quote-card2.barsik-background {
  background: url('../src/components/border-quotes/memes/barsik.png') no-repeat;
}